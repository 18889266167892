import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const SelfEepairingConstructionMaterials: React.FC = () => {
  return (
    <>
      <SEO
        title="Huge Benefits Of Self-Repairing Construction Materials"
        description="An unprecedented development that allows the use of self-repairing building material has emerged at MIT. Only time will tell if this will turn the concept of construction upside down."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Self-Repairing Construction Materials - What Are They?
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/self-repairing-construction-materials/image-1.jpeg"
            alt="Modular home"
            title="Modular home"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          If you have been a part of the construction business for a while now,
          you already know about self-healing concrete. This material also works
          in a similar way. While it does not require any kind of human
          intervention to fix cracks or scratches on the surface, it still does
          need some external input like UV light, heat or chemical treatment as
          a catalyst.
        </Typography>
        <Typography variant="h2" color="blue">
          What Are Self-Repairing Materials?
        </Typography>
        <Typography variant="body1">
          With research and development, chemical engineers at the Massachusetts
          Institute of Technology have come up with new material that can
          strengthen, grow, and also repair itself. It uses the carbon dioxide
          from the atmosphere the same way plants do.
        </Typography>
        <Typography variant="body1">
          Before this, there was no other material that could mimic these
          aspects of a live object, even though it’s not reproducing. Such
          developments have taken the construction industry ahead for several
          years.
        </Typography>
        <Typography variant="h3">How Does It Look?</Typography>
        <Typography variant="body1">
          Without any mixes or combinations, this material has a more gel-like
          appearance. However, when implemented, it reacts with the carbon
          dioxide in the air, changing its appearance. As per the theory, the
          material can get prepared and transported as a lightweight gel.
        </Typography>
        <Typography variant="body1">
          Eventually, it will grow into its final form, which is more practical,
          but a lot heavier as well. In short, they are easy to handle and
          distribute in their original state. Only when they come into contact
          with the atmosphere and sunlight is when it hardens and solidifies.
        </Typography>
        <Typography variant="body1">
          Hence it can save a lot of money and energy while being transported.
          This approach is directly inspired by plants and can transform carbon
          dioxide from the air to solid, with the help of only sunlight.
        </Typography>
        <Typography variant="h2" color="blue">
          How Do I Use Self-Repairing Construction Materials?
        </Typography>
        <Typography variant="body1">
          Earlier, recurring roadway maintenance used to be a resource-heavy
          task. It required a lot of extra time, labour and money. However, with
          this new material, that is not the case anymore. In addition to saving
          money and time, this material also has a number of environmental
          benefits.
        </Typography>
        <Typography variant="body1">
          You can use this firm as an additive or coating, lending its
          self-healing properties to the pre-existing material. This will help
          increase the frequency of maintenance work while also enhancing the
          durability of the structure.
        </Typography>
        <Typography variant="h3">How Does It Help?</Typography>
        <Typography variant="body1">
          The name self-healing material already explains its main advantages.
          For starters, it improves logistic capabilities as it is light. Its
          self-repairing capabilities also reduce the requirement for
          construction maintenance. This way, it can help save money, time and
          effort required.
        </Typography>
        <Typography variant="body1">
          However, that is not where the list ends. There are many other
          environmental benefits that you can enjoy with this material. For one,
          it actively helps remove carbon dioxide from the air.
        </Typography>
        <Typography variant="body1">
          In addition, its manufacturing does not require any use of fossil
          fuels which makes it more eco-friendly and sustainable. It uses a
          polymer of Aminopropyl Methacrylamide with glucose, chloroplast
          derived from spinach leaves and glucose oxidase.
        </Typography>
        <Typography variant="h3">
          Why Is The Material Gaining Rapid Popularity?
        </Typography>
        <Typography variant="body1">
          The sheer usability and practicality of the material ensure that it
          will have several positive implications in the construction business.
          The scientists at MIT have found an alternate way to utilise the
          abundant carbon available. Their main approach was to make a material
          that is not just carbon neutral but carbon negative.
        </Typography>
        <Typography variant="body1">
          With this information, it is quite clear how this material will soon
          rise in popularity. This will surely change the whole construction
          industry. However, there are much more research and development going
          on in building material in order to make them even better and more
          useful.
        </Typography>
        <Typography variant="body1">
          As of now, the developed material takes self-healing properties to
          another level. With only ambient light, it can capture carbon dioxide
          and changes the gas into a solid form.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SelfEepairingConstructionMaterials
